<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>Filtros</h5>
    </b-card-header>
    <b-card-body>
      <b-row>

        <b-col
            cols="12"
            md="4"
            class="mb-md-0"
        >
        <b-form-group label="Usuario">
          <v-select
              v-model="filterUserId"
              label="fullName"
              :options="fetchUniqueUsers"
              class="w-100"
              :reduce="option => option.id"
              :searchable="false"
              :clearable="false"
              @input="handleSelection"

          />
        </b-form-group>

        </b-col>

        <b-col
            cols="12"
            md="4"
            class="mb-md-0"
        >
          <b-form-group label="Período">

            <flat-pickr
                v-model="fechaSolicitud"
                class="form-control"
                :config="configDate"
                @input="handleSelection"
            />

          </b-form-group>

        </b-col>

        <b-col
            cols="12"
            md="2"
            class="mb-md-0"
            align-self="end"
        >
            <b-button
                variant="primary"
                class="w-100 mb-1"
                :disabled="isButtonDisabled"
                @click="updateFilters"
            >
              <span class="text-nowrap">Aplicar</span>
            </b-button>
        </b-col>


          <b-col
              cols="12"
              md="2"
              class="mb-md-0"
              align-self="end"
          >
              <b-button
                  variant="secondary"
                  class="w-100 mb-1"
                  :disabled = "isButtonDisabled"
                  @click="resetControls"
              >
                <span class="text-nowrap">Restablecer</span>
              </b-button>
          </b-col>


      </b-row>

    </b-card-body>

  </b-card>
</template>

<script>
import { BCard, BCardHeader, BFormGroup, BCardBody, BRow, BCol, BFormInput, BButton} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { Spanish } from "flatpickr/dist/l10n/es.js"
import moment from 'moment'

import { fetchTypeOptions, fetchStatusOptions } from '@/modules/common/utils'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import usersList from '@/views/apps/user/users-list/UsersList.vue'
import { fetchUniqueUsers } from '../store/auditoria/getters'

export default {
  name: 'AuditoriaFilters',

  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    flatPickr,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
  },

  data(){
    return {
      estadoFilter: null,
      estadoOptions: fetchStatusOptions(),
      typeUserFilter: null,
      usersLists: [ ],
      fechaSolicitud: null,
      searchQuery: '',
      filterUserId: null,
      isButtonDisabled: true,

      configDate: {
        dateFormat: 'Y-m-d',
        altInput: true,
        altFormat: "D, j-F-Y",
        locale: Spanish,
        mode: 'range'
      },
    }
  },

  computed: {

    ...mapGetters('logs-module', ['fetchLogsAuditoria', 'fetchUniqueUsers'])

  },


  methods: {

    ...mapActions('logs-module', ['getLogs']),
    ...mapMutations('logs-module', [ 'setFiltersCriteria', 'setFilterDate']),

    handleSelection() {
      if (this.filterUserId || this.fechaSolicitud) {
        this.isButtonDisabled = false
      } else {
        this.isButtonDisabled = true
      }
    },

    resetControls() {
      this.filterUserId = null // Restablecer el v-select a su valor predeterminado (null)
      this.fechaSolicitud = "" // Restablecer el flat-pickr a su valor predeterminado (null)
      this.updateFilters()
      this.isButtonDisabled = true
    },

    updateFilters(){

      const filtersCriteria = []
      if ( this.filterUserId != null )
        filtersCriteria.push( { field: 'id', values: [ this.filterUserId ] } )
      if ( this.fechaSolicitud != null ){
        let dates
        if ( this.fechaSolicitud == "" ) dates = []
        else dates = this.fechaSolicitud.split(' a ');
        this.setFilterDate(dates)
      }

      this.setFiltersCriteria( filtersCriteria )
    },

    async loadData(){
      await this.getLogs()
    }

  },

  async created() {
      await this.loadData()
  }

}
</script>


<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
